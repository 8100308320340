import React from "react";
import { Link } from "gatsby";
import Img from "gatsby-image";
import '../style/list-room.less';

class RoomItem extends React.Component {
    render() {
        return (
            <div className="item col s12 m6">
                <div className="row flex">
                    <div style={{position: 'relative'}} className="col s12 image">
                        <Img className='room-image' style={{width: '100%'}} 
                            fixed={
                                this.props.data.node.frontmatter.image
                                    .childImageSharp.fixed
                            }
                        />
                        <span className='room-tag text-white'>{this.props.data.node.frontmatter.title}</span>
                        <span className='room-price text-white background-color-primary'>Desde ${this.props.data.node.frontmatter.price}</span>
                        <Link
                            to={this.props.data.node.fields.slug}
                            title={this.props.data.node.frontmatter.title}
                            aria-label={this.props.data.node.frontmatter.title}
                            className="overlay-link"
                            style={{ opacity: 0 }}
                        >
                            {this.props.data.node.frontmatter.title}
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default function(props) {
    const data = props.data.allMarkdownRemark.edges;
    let items = [];
    data.forEach(function(e, i) {
        items.push(<RoomItem key={e.node.id} data={e} />);
    });
    return <div className="row">{items}</div>;
}
