import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import RoomItems from "./items-room";

export default function() {
    const query = useStaticQuery(graphql`
        query roomList {
            allMarkdownRemark(
                filter: { fileAbsolutePath: { regex: "/rooms/" }, frontmatter: {isMain: {eq: true}} }
                limit: 6
                sort: { fields: [frontmatter___price], order: DESC }
            ) {
                edges {
                    node {
                        id
                        frontmatter {
                            title
                            description
                            price
                            image {
                                publicURL
                                childImageSharp {
                                    fixed(width: 500, height:350) {
                                        srcSet
                                        ...GatsbyImageSharpFixed
                                    }
                                    id
                                }
                            }
                        }
                        fields {
                            slug
                        }
                    }
                }
            }
        }
    `);

    return (
        <section id="room" className="container">
            <h1 className='text-primary'>HABITACIONES</h1>
            <p className='sub-header'>- PROMOCIONES -</p>
            <RoomItems data={query} />
        </section>
    );
}
