import React from "react";
import { StaticQuery, graphql } from "gatsby";
import "../style/service.less";

class Service extends React.Component {

    render() {
        return (
            <section id="service" className="container">
                <div className="section-title">
                    <h1 className='text-primary'>SERVICIOS</h1>
                </div>
                <div className="col s12 details">
                    {this.props.services.description && (
                        <p className="text-tertiary center">
                            {this.props.services.description}
                        </p>
                    )}
                    <div className='row'>
                        <div className='col s12 m6 service-detail'>
                            <img alt='key' srcSet='/images/key.png'/>
                            <p>LLave personal</p>
                        </div>
                        <div className='col s12 m6 service-detail'>
                            <img alt='shower' srcSet='/images/shower.png'/>
                            <p>Baño privado o baño compartido</p>
                        </div>
                        <div className='col s12 m6 service-detail'> 
                            <img alt='tv' srcSet='/images/tv.png'/>
                            <p>Todas nuestras habitaciones cuenta con televisión</p>
                        </div>
                        <div className='col s12 m6 service-detail'>
                            <img srcSet='/images/wifi.png' alt='wifi'/>
                            <p>WiFi disponible en todas nuestras habitaciones</p>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default () => (
    <StaticQuery
        query={graphql`
            query {
                site {
                    siteMetadata {
                        services {
                            description
                        }
                    }
                }
            }
        `}
        render={data => <Service services={data.site.siteMetadata.services} />}
    />
);